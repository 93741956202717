import React from "react"
import { Row, Col, Container } from "react-bootstrap"

import Layout from "components/Layout/de"
import Image from "components/Image/index"
import LayerAnimation from "components/LayerAnimation/LayerAnimation"
import Slider from "components/Slider/Simple"
import Star from "components/Icons/Star"
import Circle from "components/Icons/Circle"
import Readmore from "components/Readmore/index"
import TechnologyPartnerLogos from "components/OurPartnersSection/TechnologyPartnerLogos"

import imgHeader from "./technology.jpg"
import imgMachine from "./machineImg.jpg"
import imgMetalworkMachine from "./metalworkMachine.jpg"
import style from "./style.module.scss"

export default function(props) {
  const header = {
    x: 44,
    y: 75,
    text: "Deep Learning Technologie",
    description:
      "Teilautomatisiertes selbstlernendes System für Bilderkennungsaufgaben.",
    image: imgHeader,
  }
  return (
    <Layout header={header} location={props.location}>
      <section className="grey-background">
        <Container>
          <Row className="py-5">
            <Col md="6">
              <Row className="py-6" style={{ marginTop: "-4vh" }}>
                <LayerAnimation />
              </Row>
            </Col>
            <Col md="6" className="py-5">
              <h4>Proprietäre Deep Learning Technologie</h4>
              <h3>
                Kontinuierlicher Lernprozess und stetige Verbesserung durch die
                neueste KI-Technologie
              </h3>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row className="pt-5">
          <Col md="8" sm="6">
            <div>
              <h2>
                Die Moonvision Software spezialisiert sich auf
                Qualitätsüberprüfung und -sicherung von Montageprodukten und
                Oberflächen.
              </h2>
              <p>
                Die Moonvision Software spezialisiert sich auf
                Qualitätsüberprüfung und -sicherung von Montageprodukten und
                Oberflächen. Alle Daten (Bild und Video) werden über ein visual
                Data-Lake mit Einsatz von <strong>Deep Learning</strong>{" "}
                gesammelt und verarbeitet, um einen kontinuierlichen Lernprozess
                und eine stetige Verbesserung in der Qualitätskontrolle zu
                gewährleisten. Da unsere Technologie mit der neuesten KI
                Technologie koordiniert wird, umfasst das Potential unserer
                Software eine{" "}
                <strong>exponentiell steigende Lernfähigkeit</strong> und
                ermöglicht, dass unser Model Training schon mit wenigen
                Aufwendungen und Daten die bestmögliche Few-Shot Objekterkennung
                auf dem Markt ist.
              </p>
              <p>
                Um die produktspezifischen Merkmale zu lokalisieren steht ein{" "}
                <strong>Labeling-Tool</strong> bereit, welches auf jede
                Qualitätseigenschaft kontinuierlich angepasst werden kann und
                schlussendlich eine sehr genaue und vollautomatisierte
                Annotierung der jeweiligen Abweichungen durchführt.
              </p>
            </div>
          </Col>
          <Col md="4" sm="6">
            <Image filename={imgMachine} alt="CNC Maschine" />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="pt-5">
          <Col>
            <div className={style.tableDesign}>
              <table>
                <thead>
                  <tr>
                    <th>Data Management</th>
                    <th>Erklärung</th>
                    <th>USP</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Team and Role Based Access Management</td>
                    <td>
                      Datensicherung und -verarbeitung kann für schnellen
                      Informationsaustausch und beste Informationssicherheit
                      bestimmten Einheiten, Personen zugeteilt und
                      standortübergreifend genutzt werden.
                    </td>
                    <td>
                      <Star />
                    </td>
                  </tr>
                  <tr>
                    <td>Infinite Versioning</td>
                    <td>
                      Unsere Lösung erlaubt eine fehlertolerante Erzeugung von
                      Trainingsdaten, da jegliche Dateneingaben widerrufbar
                      sind.
                    </td>
                    <td>
                      <Star />
                    </td>
                  </tr>
                  <tr>
                    <td>Multiple Geometric Shapes</td>
                    <td>
                      Die Moonvision Toolbox ermöglicht die Kennzeichnung aller
                      Merkmalarten mit verschiedenen Geometrischen Figuren.
                      Damit gewährleisten wir, dass Ist- und Sollzustände für
                      den Anwendungsfall spezifisch und korrekt erfasst werden
                      können.
                    </td>
                    <td>
                      <Circle />
                    </td>
                  </tr>
                  <tr>
                    <td>Video Snapshot Extraction</td>
                    <td>
                      Unser Unternehmen stellt ein Tool auf Basis lernender
                      Algorithmen bereit, welches Einsparungen in
                      Hardwarekomponenten erlaubt, da unsere Technologie wie
                      Sensoren, Vorgänge zeitlich abgrenzen und zur richtigen
                      Zeit Schnappschüsse auslösen kann.
                    </td>
                    <td>
                      <Circle />
                    </td>
                  </tr>
                  <tr>
                    <td>Equivalent Inferences On-premise and Online</td>
                    <td>
                      Unsere Technologie ermöglicht ein Online- und
                      Offline-Deploy mit 100% gleichem Ergebnis. Sie können
                      flexibel online Tätigkeiten und virtuelle Tests
                      durchführen und offline die Toolbox mit den neuen
                      Ergebnissen aktualisieren. Damit garantieren wir
                      Prozesssicherheit.
                    </td>
                    <td>
                      <Star />
                    </td>
                  </tr>
                  <tr>
                    <td>Cloud Scale GPU Utilization</td>
                    <td>
                      Alle Daten werden durch ein zentrales Cloud-based visual
                      Data Lake gesammelt und verarbeitet. Wir stellen
                      Analyseergebnisse rasch zur Verfügung, da permanent und
                      skalierend GPU zur Verfügung stehen.
                    </td>
                    <td>
                      <Star />
                    </td>
                  </tr>
                  <tr>
                    <td>Machine Vision and IP Camera</td>
                    <td>
                      Wir unterstützen GeniCam Standards (z.B. Basler, IDS, …)
                      und gängige Videostreaming Standards auf IP Basis (z.B.
                      H264, RTSP, HTTP).
                    </td>
                    <td>
                      <Star />
                    </td>
                  </tr>
                  <tr>
                    <td>Transfer Learning</td>
                    <td>
                      Durch unsere vordefinierte Software reduzieren wir den
                      Bedarf an Trainingsdaten um Faktor 3-10, da wir
                      branchenspezifisches Know-How für die Spezialisierung von
                      Modellen zum Einsatz bringen können. Dadurch ermöglichen
                      wir die Erkennung von neuen und seltenen Phänomenen.
                    </td>
                    <td>
                      <Star />
                    </td>
                  </tr>
                  <tr>
                    <td>Anomaly Segmentation</td>
                    <td>
                      Wir können unbekannte und auffällige Merkmale
                      lokalisieren, wie zum Beispiel Auffälligkeiten der
                      Faserstruktur, Oberflächenbeschaffenheit,
                      Bauteilzusammensetzung.
                    </td>
                    <td>
                      <Circle />
                    </td>
                  </tr>
                  <tr>
                    <td>Traceback Predictions to Single Training Samples</td>
                    <td>
                      Unsere Technologie besitzt automatische Vorgänge zum
                      Aufspüren von fehlerhaften Eingaben in den Trainingsdaten.
                    </td>
                    <td>
                      <Circle />
                    </td>
                  </tr>
                  <tr>
                    <td>Model Introspection</td>
                    <td>
                      Die Moonvision Software visualisiert die Funktionsweisen
                      von Bildanalyseschritten, wodurch Modellverbesserungen
                      nicht zufällig sondern systematisch ermöglicht werden.
                    </td>
                    <td>
                      <Star />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Container>
      <Slider />
      <Container>
        <Row className="py-5">
          <Col md="4">
            <Image filename={imgMetalworkMachine} alt="metal-work-machine" />
          </Col>
          <Col md="8">
            <h3>
              Für uns ist es wichtig, dass jeder Betrieb unsere Software mit
              einfachen Mitteln einsetzen kann.
            </h3>
            <p>
              Dies als eines unserer Ziele, erlaubt es jedes Kamerasystem mit
              unserer Software zu verknüpfen und ermöglicht uns
              hardwareunabhängig zu bleiben. Durch die freie Wahl von
              Bildverarbeitungssystemen können wir Merkmale bis zur{" "}
              <strong>mikroskopischen Ebene</strong> verarbeiten und jedes
              entscheidende Detail identifizieren.
            </p>
            <p>
              Da unsere Software autark entworfen wurde, ist sie die perfekte
              Lösung für jedes Unternehmen und jede Industrie. Die{" "}
              <strong>vielfältigen Einsatzmöglichkeiten</strong> unseres
              Produktes ermöglichen eine Integration in jeden
              Herstellungsprozess. Somit kann unsere Lösung sowohl Wareneingang
              oder -ausgang, als auch nach jedem Verarbeitungsschritt einfach
              und durch geringe Maßnahmen in eine Fertigung gekoppelt werden.
              Aufgrund der verschiedenen Industrien sehen wir weiters notwendig,
              dass neben den stehenden Produktkontrollen auch sich bewegende
              Erzeugnisse im Produktionsfluss analysiert werden, um jegliche
              Störungen zu vermeiden.
            </p>
            <p>
              <strong>
                Durch die große Vielfältigkeit unserer Technologie, ist sie
                nachhaltig ausgelegt und kann je nach Anforderung für den
                Betrieb optimiert werden.
              </strong>
            </p>
          </Col>
        </Row>
      </Container>
      <TechnologyPartnerLogos />
      <Container>
        <Row className="py-5">
          <Col md="12">
            <h2>Lerne mehr über unsere Technologie auf Medium (en)</h2>
          </Col>
          <Readmore />
        </Row>
      </Container>
    </Layout>
  )
}
