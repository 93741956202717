module.exports = {
  // contact
  contactUs: "Kontaktieren Sie uns",
  callUs: "Rufen Sie an",

  // Navigation.js
  navigationProducts: "Produkte",
  navigationTechnology: "Technologie",
  navigationUsecases: "Use Cases",
  navigationConsulting: "Consulting",
  navigationPartner: "Partner",
  navigationCareer: "Karriere",
  navigationSignup: "Kostenlos probieren",

  // Cookies Popup
  cookieText:
    "<p>Diese Website verwendet Cookies, um sicherzustellen, dass Sie das beste Ergebnis auf unserer Website haben.</p><a href='https://www.cookiesandyou.com/'     target='_blank' rel='noopener noreferrer'>Mehr Erfahren</a>",
  cookieAccept: "Akzeptieren",

  // Contact Us
  contactTitle: "Kontaktieren Sie uns gleich:",
  contactCall: "Anruf",
  contactEmail: "Email",

  // Contact Section
  contactSectionHeader:
    "Unsere Produkte können sofort eingesetzt werden.<br/>Ohne Prozessänderungen, kein Umbau - einfache Verwendung",
  contactSectionText:
    "Lernen Sie mehr über unsere Produktgruppe über praktische Beispiele, die Einsatzgebiete und im gespräch mit unseren Experten",
  contactSectionButton: "Mehr über die MoonVision Lösungen erfahren",
  goToProductsButton: "Unsere Produkte",

  // Product
  productOverview: "Produktübersicht",
  productDetails: "Produktbeschreibung",
  productTechnical: "Technische Spezifikation",
  productContactNext: "Unverbindliche Anfrage",
  productContactPoints:
    "<li>Angebot</li><li>Testbetrieb</li><li>Produktdemonstration</li><li>Technischer Support</li>",
  productContactCTA: "Weitere Fragen zum",
  freeconsultation: `Laden Sie Bilder für einen Test von {product} hoch`,
  freeconsultationText:
    "Laden Sie hier Bilder von Ihnen für einen unverbindlichen Materialcheck. Wir halten Privatsphäre hoch und löschen alle hochgeladenen Informationen nach der Einschätzung verbindlich. Unsere Daten security guidelines stellen sicher, dass kein zugriff von außen möglich ist und die Vertraulichkeit der Daten halten wir sehr hoch.",
  freeconsultationImages:
    "Klicken Sie hier oder ziehen Sie Bilder auf dieses Feld. Beste Ergebnisse werden mit 10-50 Bilder erzielt.",
  freeconsultationImagesCount: `{images, number} {images, plural, one {Bild ist} other {Bilder sind}} bereit für den Upload:`,
  freeconsultationSubmit: "Abschicken für unverbindliches Materialsichten",
  freeconsultationSuccess: "Danke für Ihre Anfrage, wir melden uns in Kürze.",
  freeconsultationError: "Bitte geben Sie eine gültige Email Adresse an.",

  // Consulting
  consultingText:
    "<h2>Bilder sind die <strong>Basis</strong> für <strong>Ihre Digitalisierung</strong></h2><p>Profitieren Sie von unseren breiten Erfahrungen im Bereich KI/AI Consulting sowie umgesetzten Projekten zu den Themen Computer Vision, Deep Learning. Machen Sie nicht die gleichen Fehler, welche andere Unternehmen bereits erlebt haben, sondern greifen Sie frühzeitig auf das praxiserprobte Wissen unserer erfahrenen Experten zurück.<p>",
  consultingBlueBoxAreasHeader: "<h2>Consulting-Bereiche</h2>",
  consultingBlueBoxVisual: "Visuelle Oberflächen-Analyse",
  consultingBlueBoxQuality: "Qualitätssicherung",
  consultingBlueBoxAI:
    "Computer Vision KI als Erweiterung Ihres bestehenden Geschäftsmodelles",
  consultingBlueBoxModel:
    "Neuartige Geschäftsmodelle auf Basis von Computer Vision KI",
  consultingBlueBoxProduct: "Produktüberwachung",
  consultingBlueBoxProcess: "Prozessüberwachung",
  consultingContactUsSectionHeader:
    "In welchen Bereichen können wir Sie unterstützen?",
  consultingContactUsSectionButton: "Kontaktieren Sie uns",

  // technology slider
  sliderBenefits: "Unsere Vorteile",
  sliderHeading1: "Data Lake",
  sliderText1: "Einmal trainieren, überall einsetzen",
  sliderHeading2: "APIs",
  sliderText2:
    "Schnittstellen zu ERP Software, Automatisierungssoftware sowie Messaging Systemen.",
  sliderHeading3: "Individuell",
  sliderText3: "Individuelle Anpassungen der Qualitätsmerkmale möglich",
  sliderHeading4: "Umfassend",
  sliderText4: "Unterstützung aller Herstellungsprozesse",
  sliderHeading5: "Daten Reports",
  sliderText5: "Bilddaten basierte reports für die Qualitätssicherung.",

  // ToolBox product
  toolBoxVideo: "Verarbeitung von Videodaten ",
  toolBoxManufacturing: "Unterstützung aller Herstellprozesse",
  toolBoxAdaptation: "Anpassung auf wechselnde Anforderungen",

  //carreer
  carreerForm: "Bewerbungsformular",
  carreerFirstName: "Vorname",
  carreerLastName: "Nachname",
  carreerTel: "Telefonnummer",
  carreerLinkedIn:
    "Ihr privates Profil, z.B. Github, Linkedin oder Ihre Webseite",
  carreerCoverLetter: "Anschreiben hochladen",
  carreerCV: "CV und Anschreiben hochladen",
  carreerAllowDataTypes:
    "Maximal 3 Detein and 15 MB pro Detei erlaubt. Erlaubte Datenformate: PDF, .doc, .docx, png, jpeg, jpg",
  carreerApplyNow: "Jetzt bewerben",
  carreerSuccessMessage: "Bewerbung erflogreich abgeschickt!",

  // footer
  footerAbout: "Über uns",
  // footerPress: "Presse",
  footerContact: "Kontakt",
  footerImprint: "Impressum",
  footerTermsConditions: "AGB",
  footerDataPrivacy: "Datenschutzerklärung",
  footerTechnology: "Technologie",
  footerOverview: "Übersicht",
  footerPartnership: "Partner",
  footerPartnershipCertification: "Zertifiizierung",
}
