import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import { FormattedMessage } from "react-intl"

import Swiper from "react-id-swiper"
import "react-id-swiper/lib/styles/scss/swiper.scss"

// Import SVGs
import image1 from "./SliderIcons/DataLake.svg"
import image2 from "./SliderIcons/APIs.svg"
import image3 from "./SliderIcons/Individual.svg"
import image4 from "./SliderIcons/Handling.svg"
import image5 from "./SliderIcons/ImageData.svg"

import style from "./style.module.scss"

const LoopModeWithMultipleSlidesPerGroup = () => {
  const params = {
    slidesPerView: 1,
    spaceBetween: 10,
    slidesPerGroup: 1,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next.swiper-button-white",
      prevEl: ".swiper-button-prev.swiper-button-white",
    },
    centeredSlides: true,
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
        slidesPerGroup: 1,
      },
      500: {
        slidesPerView: 1,
        spaceBetween: 10,
        slidesPerGroup: 1,
      },
    },
  }
  return (
    <section className="blue-background py-5 my-5">
      <Container>
        <Row>
          <Col>
            <h3>
              <FormattedMessage id="sliderBenefits" />
            </h3>
          </Col>
        </Row>
        <Row className="py-5">
          <Swiper {...params}>
            <div className={style.slide}>
              <img
                src={image1}
                width={60}
                height={60}
                alt="DataLake illustration"
              />
              <h4>
                <FormattedMessage id="sliderHeading1" />
              </h4>
              <p>
                <FormattedMessage id="sliderText1" />
              </p>
            </div>
            <div className={style.slide}>
              <img src={image2} width={60} height={60} alt="Api individual" />
              <h4>
                <FormattedMessage id="sliderHeading2" />
              </h4>
              <p>
                <FormattedMessage id="sliderText2" />
              </p>
            </div>
            <div className={style.slide}>
              <img src={image3} width={60} height={60} alt="Individual" />
              <h4>
                <FormattedMessage id="sliderHeading3" />
              </h4>
              <p>
                <FormattedMessage id="sliderText3" />
              </p>
            </div>
            <div className={style.slide}>
              <img
                src={image4}
                width={60}
                height={60}
                alt="Handling illustration"
              />
              <h4>
                <FormattedMessage id="sliderHeading4" />
              </h4>
              <p>
                <FormattedMessage id="sliderText4" />
              </p>
            </div>
            <div className={style.slide}>
              <img
                src={image5}
                width={60}
                height={60}
                alt="Data illustration"
              />
              <h4>
                <FormattedMessage id="sliderHeading5" />
              </h4>
              <p>
                <FormattedMessage id="sliderText5" />
              </p>
            </div>
          </Swiper>
        </Row>
      </Container>
    </section>
  )
}
export default LoopModeWithMultipleSlidesPerGroup
