import React from "react"
import { Col } from "react-bootstrap"

class Readmore extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      articles: [],
    }
  }
  componentDidMount() {
    fetch(
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/moonvision"
    )
      .then(response => {
        return response.json()
      })
      .then(response => {
        if (response.status === "ok") {
          const myArticles = response.items.map(item => {
            const event = new Date(item.pubDate)
            return { ...item, event: event }
          })
          this.setState({ articles: myArticles })
        } else {
          this.setState({ articles: [] })
        }
      })
  }
  render() {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return (
      <React.Fragment>
        {this.state.articles.map(item => (
          <Col md="6" lg="4" key={item.link}>
            <div className="card" style={{ height: "90%" }}>
              <div className="card-body">
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <h3>{item.title}</h3>
                </a>
                <p>
                  Date: Medium,{" "}
                  {item.event.toLocaleDateString("de-DE", options)}
                  <br />
                  Author: {item.author}
                </p>
              </div>
            </div>
          </Col>
        ))}
      </React.Fragment>
    )
  }
}

export default Readmore
